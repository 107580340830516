<template>
  <div class="admin-user-list">
    <loading-box v-bind:loading="loading"></loading-box>
    <div class="filter-bar">
      <div>
        <b-input-group size="sm">
          <b-form-input
            v-model="filter"
            type="search"
            id="filterInput"
            placeholder="Type to Filter"
            class="text-input"
            @keypress="inputPress"
          ></b-form-input>
          <b-input-group-append>
            <b-button v-if="!hideAdvanced || filterMethod === 'remote'" @click="showAdvanced = !showAdvanced">Advanced</b-button>
            <b-button v-if="filterMethod ==='remote'" @click="applyFilter" variant="primary">Apply</b-button>
          </b-input-group-append>
        </b-input-group>
        <div v-show="showAdvanced" class="advanced-filter">
          <search-filters :filters="filters" @change="advFilterChange" @press-enter="applyFilter"></search-filters>
        </div>
      </div>
    </div>
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="userList" :fields="userFields" select-mode="single" selected-variant="success"
             :selectable="isSelectable" @row-selected="bvSelectChange"
             :filter="filterMethod === 'local' ? filter : undefined" :filterIncludedFields="filterOn" :current-page="currentPage" :per-page="perPage"
             th-class="bv-table-header" v-on:filtered="filterChange" ref="userTable"
             class="bv-table">
      <!--Select All Header Element-->
      <template v-slot:head(selected)="row">
        <b-form-checkbox v-model="selectAll" v-on:change="toggleSelectAll"></b-form-checkbox>
      </template>
      <!--Select Checkbox Row Element-->
      <template v-slot:cell(selected)="row">
        <b-form-checkbox v-model="row.item.selected" v-on:change="selectChange(row.item, $event)"></b-form-checkbox>
      </template>
      <!--Subscription Exempt Checkbox Row Element-->
      <template v-slot:cell(is_subscription_exempt)="row">
        <b-form-checkbox v-model="row.item.is_subscription_exempt" disabled></b-form-checkbox>
      </template>
      <!--Admin Checkbox Row Element-->
      <template v-slot:cell(admin)="row">
        <b-form-checkbox v-model="row.item.admin" v-on:change="adminChange(row.item, $event)" disabled></b-form-checkbox>
      </template>
      <!--Device Count Row Element-->
      <template v-slot:cell(devices)="row">
        <div class="row-icon-button" @click="row.toggleDetails">
          {{row.item.devices.length}}
          <i v-if="!row.detailsShowing" :class="$config.icons.twisties.collapsed"></i>
          <i v-if="row.detailsShowing" :class="$config.icons.twisties.expanded"></i>
        </div>
      </template>
      <!--User Actions Row Element-->
      <template v-slot:cell(actions)="row">
        <i class="row-icon-button row-action" :class="$config.icons.general.edit"
           v-b-tooltip.hover title="Edit User" @click="userActionEdit(row.item)"></i>
        <i class="row-icon-button row-action" :class="$config.icons.general.assign" v-b-tooltip.hover title="Assign Devices"
           @click="userActionAssign(row.item)"></i>
        <i class="row-icon-button row-action" :class="$config.icons.general.sendNotification" v-b-tooltip.hover title="Send Test Notification"
           @click="userActionTestNotification(row.item)"></i>
        <i class="fa fa-times-circle row-icon-button-danger row-action ml-3" v-b-tooltip.hover title="Delete User"
           @click="userActionDelete(row.item)"></i>
      </template>
      <!--Device Details row-detail element-->
      <template v-slot:row-details="row">
        <div class="child-card">
          <admin-device-list hide-filter hide-checkboxes :devices="row.item.devices" :show-fields="deviceFields" v-on:save="refreshUserList"></admin-device-list>
        </div>
      </template>
      <!-- Show if the user has a role with fleet access -->
      <template v-slot:cell(has_fleet_access)="row">
        <span v-if="row.item.roles && hasRole(row.item.roles, 'fleet_user')">Yes</span>
      </template>

    </b-table>
    <div class="green-divider"></div>
    <div class="flex-row w-100 justify-content-between align-items-center">
      <div>
        Results: {{totalRows}}
      </div>
      <div class="flex-row align-items-center">
        <b-form-select
          variant="dark"
          v-model="perPage"
          id="perPageSelect"
          size="sm"
          :options="pageOptions"
          class="page-select">
        </b-form-select>
        <b-pagination
          variant="dark"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="page-buttons"></b-pagination>
      </div>
    </div>

    <!--    User Edit Modal Template-->
    <b-modal id="modal-edit-user" centered class="modal-content" size="xl" hide-footer title="Edit User" no-close-on-backdrop>
      <admin-edit-user is-modal modal="modal-edit-user" :user="clickedUser" v-on:save="onSave()"></admin-edit-user>
    </b-modal>

    <!--    User Assign Device Modal Template-->
    <b-modal id="modal-assign-device" centered class="modal-content" size="xl" hide-footer title="Assign Device to User">
      <admin-assign-devices :user="clickedUser" modal="modal-assign-device" v-on:save="onAssign()"></admin-assign-devices>
    </b-modal>
  </div>
</template>

<script>
import LoadingBox from '../helpers/LoadingBox'
import AdminEditUser from './AdminEditUser'
import * as AlertHelper from '../helpers/AlertHelper'
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
import SearchFilters from '../shared/SearchFilters'

export default {
  name: 'admin-user-list',
  components: {
    SearchFilters,
    LoadingBox,
    AdminDeviceList: () => import ('./AdminDeviceList'),
    AdminEditUser,
    AdminAssignDevices: () => import ('./AdminAssignDevices')
  },
  props: {
    users: Array,
    singleSelectMode: Boolean,
    multiSelectMode: Boolean,
    hideAdvanced: Boolean
  },
  data: function () {
    return {
      loading: true,
      // Table Filtering
      filterMethod: 'remote', // 'local' for bvTable filtering of static lists. 'remote' for API filtering.
      filter: '',
      filterOn: [],
      // Holds result of bvTable filters when applied
      filteredUserList: null,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      // Bootstrap Select for single selection
      isSelectable: false,
      // User Selection
      selectAll: false,
      selected: [],
      // Advanced Filtering
      filterOpts: {},
      showAdvanced: false,
      filters: [
        {
          label: 'Filter By Name',
          type: 'string',
          placeholder: 'Name Must Include...',
          fieldName: 'name'
        },
        {
          label: 'Filter By Email',
          type: 'string',
          placeholder: 'Email Must Include...',
          fieldName: 'email'
        },
        {
          label: 'Filter By Mobile#',
          type: 'string',
          placeholder: 'Mobile # Must Include...',
          fieldName: 'mobile'
        },
        {
          label: 'is Admin',
          type: 'boolean',
          fieldName: 'admin'
        }
      ],
      // Passed to 'user' Prop of AdminEditUser Component
      clickedUser: null,
      userFields: [
        { key: 'selected',
          label: '-'
        },
        { key: 'userId',
          label: 'Uid',
          sortable: true
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'email',
          label: 'Email',
          sortable: true
        },
        { key: 'devices',
          label: '# Devices',
          sortable: true
        },
        { key: 'admin',
          label: 'Admin',
          sortable: true
        },
        { key: 'is_subscription_exempt',
          label: 'Sub Exempt',
          sortable: true
        },
        { key: 'has_fleet_access',
          label: 'Fleet User?',
          sortable: true
        },
        { key: 'actions',
          label: 'Actions'
        }
      ],
      userList: [],
      // Which fields to show in row details
      deviceFields: ['device_code', 'device_name', 'has_subscription', 'location.datetime', 'actions'],
      applyFilters: false
    }
  },
  async created () {
    if (this.singleSelectMode && this.multiSelectMode) {
      console.error('Admin User List: Flagged for both single AND multi select modes!')
    } else if (this.singleSelectMode) {
      // Remove the select field, use row-select instead.
      this.userFields = this.userFields.filter(x => x.key !== 'selected')
      this.isSelectable = true
    }
    if (this.users) {
      // Disable Advanced Filter Controls
      this.showAdvanced = false
      this.filterMethod = 'local'
      this.userList = this.users
      // We've been given a list of specific users to display. Do a thing to get the right users.
      // TODO - How do we identify users? Email? Users ID? What does a device provide to track ownership if queried?
    } else {
      await this.refreshUserList()
      this.loading = false
    }
  },
  methods: {
    onSave: async function () {
      await this.refreshUserList()
    },
    hasRole: function (roles, roleName) {
      return roles.some(role => role.name === roleName)
    },
    refreshUserList: async function () {
      // if (this.filter || Object.keys(this.filterOpts).length > 0) {
      //   this.applyFilter()
      // } else {
      console.log('Filter: ', this.filter)
      console.log('Filter Adv: ', this.filterOpts)
      // let userTotalResponse = await DataProvider.getAdminUserCount()
      // if (userTotalResponse.success) {
      //   this.totalRows = userTotalResponse.data.number_of_users
      // } else {
      //   ErrorHelper.displayDataErrorToast(userTotalResponse)
      // }
      this.userList = this.dataPageProvider
      if (this.$refs.userTable) {
        this.$refs.userTable.refresh()
      }
      // }
    },
    dataPageProvider: async function (ctx) {
      this.loading = true
      let queryData = {
        start: (ctx.currentPage - 1) * ctx.perPage,
        page_size: ctx.perPage
        // fake_sleep: 5  // You can pass a fake_sleep here and the server will wait that many seconds before replying - for testing
      }
      if (this.applyFilters) {
        queryData.anywhere = this.filter
        if (this.showAdvanced) {
          Object.assign(queryData, this.filterOpts)
        }
      }
      let resp = await DataProvider.getAdminUserListPaginated(queryData)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        this.loading = false
        if (Object.hasOwn(resp.data, 'count')) {
          this.totalRows = resp.data.count
        }
        resp.data.users.forEach((user) => { user.selected = !!this.selected.some(x => x.user_id === user.user_id) })
        return resp.data.users
      }
    },
    updateTableFromJSON: function (userData) {
      this.userList = userData
      console.log(this.userList)
    },
    // ----- Actions from User Row -------
    userActionDelete: async function (user) {
      let res = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${user.email} ?`, {
        title: 'Confirm Delete',
        okVariant: 'danger',
        centered: true
      })
      if (res) {
        console.log('Confirmed Delete for ', user)
        let response = await DataProvider.adminDeactivateUser(user.id)
        if (response.success) {
          AlertHelper.successToast('User Deactivated Successfully.', 'Deactivation Successful')
          this.refreshUserList()
        } else {
          ErrorHelper.displayDataErrorToast(response)
        }
      }
    },
    userActionEdit: function (user) {
      console.log('Clicked Edit for ', user)
      this.clickedUser = user
      this.$bvModal.show('modal-edit-user')
    },
    userActionAssign: function (user) {
      console.log('Clicked assign Device for ', user)
      this.clickedUser = user
      console.log(this.clickedUser)
      this.$bvModal.show('modal-assign-device')
    },
    onAssign: async function () {
      this.$bvModal.hide('modal-assign-device')
      await this.refreshUserList()
    },
    userActionTestNotification: async function (user) {
      console.log('Clicked TEST NOTIFICATION ', user)
      let res = await this.$bvModal.msgBoxConfirm(`Are you sure you want to send ${user.email} a TEST NOTIFICATION ?`, {
        title: 'Confirm Send Notification',
        okVariant: 'warning',
        centered: true
      })
      if (res) {
        console.log('Confirmed SEND TEST NOTIFICATION to ', user)
        let resp = await DataProvider.sendTestNotificationToUser(user.email)
        if (resp.success) {
          this.$bvToast.toast(`Test Notification Sent to ${user.email}`,
            {
              title: 'Notification Sent',
              variant: 'success',
              toaster: 'b-toaster-top-center'
            })
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    },
    toggleSelectAll: function (event) {
      if (this.filteredUserList) { // If we've filtered the device list, respect the filter.
        if (event) {
          this.filteredUserList.forEach(x => {
            x.selected = true
          })
          this.selected = this.filteredUserList
        } else {
          this.clearSelectAll()
          this.selected = []
        }
      } else {
        if (event) {
          console.log('Table Value: ', this.$refs.userTable.localItems)
          this.$refs.userTable.localItems.forEach(x => { x.selected = true })
          this.selected = this.$refs.userTable.localItems
        } else {
          this.clearSelectAll()
          this.selected = []
        }
      }
      this.$emit('change', this.selected)
    },
    bvSelectChange: function (selection) {
      this.$emit('change', selection)
    },
    selectChange: function (user, event) {
      // console.log(this.userList)
      user.selected = event // Manually update select state, otherwise result will be a 'click behind'
      if (event) {
        this.selected.push(user)
      } else {
        this.selected = this.selected.filter(x => x.user_id !== user.user_id)
      }
      // this.selected = this.userList.filter(x => { return x.selected })
      this.$emit('change', this.selected)
    },
    clearSelectAll: function () {
      this.$refs.userTable.localItems.forEach(x => { x.selected = false })
      this.selectAll = false
      this.selected = []
      this.$emit('change', this.selected)
    },
    filterChange: function (filteredUsers) {
      // Only take action if we're using local filtering
      if (this.filterMethod === 'local') {
        console.log(filteredUsers)
        this.clearSelectAll()
        this.$emit('change', [])
        this.filteredUserList = filteredUsers
      }
    },
    advFilterChange: function (newFilter) {
      this.filterOpts = newFilter
    },
    applyFilter: async function () {
      this.loading = true
      this.applyFilters = true
      await this.refreshUserList()
      // let responseData
      // if (this.showAdvanced) {
      //   responseData = await DataProvider.adminGetUserList(this.filter, this.filterOpts)
      // } else {
      //   responseData = await DataProvider.adminGetUserList(this.filter)
      // }
      // console.log(responseData)
      // if (!responseData.success) {
      //   ErrorHelper.displayDataErrorToast(responseData)
      //   this.loading = false
      //   return
      // }
      // // TODO - Check this format
      // this.updateTableFromJSON(responseData.data)
      this.loading = false
    },
    activateChange: function (user, event) {
      user.activated = event
      this.saveUser(user)
    },
    adminChange: function (user, event) {
      user.admin = event
      this.saveUser(user)
    },
    saveUser: async function (user) {
      console.log('Save User Object Called for :', user)
      let res = await DataProvider.adminUpdateUser(user.id, user.name, user.email, user.mobile, user.admin, user.activated)
      if (res.success) {
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    inputPress: function (event) {
      if (event.charCode === 13) {
        this.applyFilter()
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .user-list{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }
</style>
