var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-user-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "filter-bar" }, [
        _c(
          "div",
          [
            _c(
              "b-input-group",
              { attrs: { size: "sm" } },
              [
                _c("b-form-input", {
                  staticClass: "text-input",
                  attrs: {
                    type: "search",
                    id: "filterInput",
                    placeholder: "Type to Filter"
                  },
                  on: { keypress: _vm.inputPress },
                  model: {
                    value: _vm.filter,
                    callback: function($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter"
                  }
                }),
                _c(
                  "b-input-group-append",
                  [
                    !_vm.hideAdvanced || _vm.filterMethod === "remote"
                      ? _c(
                          "b-button",
                          {
                            on: {
                              click: function($event) {
                                _vm.showAdvanced = !_vm.showAdvanced
                              }
                            }
                          },
                          [_vm._v("Advanced")]
                        )
                      : _vm._e(),
                    _vm.filterMethod === "remote"
                      ? _c(
                          "b-button",
                          {
                            attrs: { variant: "primary" },
                            on: { click: _vm.applyFilter }
                          },
                          [_vm._v("Apply")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showAdvanced,
                    expression: "showAdvanced"
                  }
                ],
                staticClass: "advanced-filter"
              },
              [
                _c("search-filters", {
                  attrs: { filters: _vm.filters },
                  on: {
                    change: _vm.advFilterChange,
                    "press-enter": _vm.applyFilter
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        ref: "userTable",
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.userList,
          fields: _vm.userFields,
          "select-mode": "single",
          "selected-variant": "success",
          selectable: _vm.isSelectable,
          filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
          filterIncludedFields: _vm.filterOn,
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          "th-class": "bv-table-header"
        },
        on: { "row-selected": _vm.bvSelectChange, filtered: _vm.filterChange },
        scopedSlots: _vm._u([
          {
            key: "head(selected)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: { change: _vm.toggleSelectAll },
                  model: {
                    value: _vm.selectAll,
                    callback: function($$v) {
                      _vm.selectAll = $$v
                    },
                    expression: "selectAll"
                  }
                })
              ]
            }
          },
          {
            key: "cell(selected)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  on: {
                    change: function($event) {
                      return _vm.selectChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.selected,
                    callback: function($$v) {
                      _vm.$set(row.item, "selected", $$v)
                    },
                    expression: "row.item.selected"
                  }
                })
              ]
            }
          },
          {
            key: "cell(is_subscription_exempt)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  attrs: { disabled: "" },
                  model: {
                    value: row.item.is_subscription_exempt,
                    callback: function($$v) {
                      _vm.$set(row.item, "is_subscription_exempt", $$v)
                    },
                    expression: "row.item.is_subscription_exempt"
                  }
                })
              ]
            }
          },
          {
            key: "cell(admin)",
            fn: function(row) {
              return [
                _c("b-form-checkbox", {
                  attrs: { disabled: "" },
                  on: {
                    change: function($event) {
                      return _vm.adminChange(row.item, $event)
                    }
                  },
                  model: {
                    value: row.item.admin,
                    callback: function($$v) {
                      _vm.$set(row.item, "admin", $$v)
                    },
                    expression: "row.item.admin"
                  }
                })
              ]
            }
          },
          {
            key: "cell(devices)",
            fn: function(row) {
              return [
                _c(
                  "div",
                  {
                    staticClass: "row-icon-button",
                    on: { click: row.toggleDetails }
                  },
                  [
                    _vm._v(" " + _vm._s(row.item.devices.length) + " "),
                    !row.detailsShowing
                      ? _c("i", { class: _vm.$config.icons.twisties.collapsed })
                      : _vm._e(),
                    row.detailsShowing
                      ? _c("i", { class: _vm.$config.icons.twisties.expanded })
                      : _vm._e()
                  ]
                )
              ]
            }
          },
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button row-action",
                  class: _vm.$config.icons.general.edit,
                  attrs: { title: "Edit User" },
                  on: {
                    click: function($event) {
                      return _vm.userActionEdit(row.item)
                    }
                  }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button row-action",
                  class: _vm.$config.icons.general.assign,
                  attrs: { title: "Assign Devices" },
                  on: {
                    click: function($event) {
                      return _vm.userActionAssign(row.item)
                    }
                  }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "row-icon-button row-action",
                  class: _vm.$config.icons.general.sendNotification,
                  attrs: { title: "Send Test Notification" },
                  on: {
                    click: function($event) {
                      return _vm.userActionTestNotification(row.item)
                    }
                  }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass:
                    "fa fa-times-circle row-icon-button-danger row-action ml-3",
                  attrs: { title: "Delete User" },
                  on: {
                    click: function($event) {
                      return _vm.userActionDelete(row.item)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "row-details",
            fn: function(row) {
              return [
                _c(
                  "div",
                  { staticClass: "child-card" },
                  [
                    _c("admin-device-list", {
                      attrs: {
                        "hide-filter": "",
                        "hide-checkboxes": "",
                        devices: row.item.devices,
                        "show-fields": _vm.deviceFields
                      },
                      on: { save: _vm.refreshUserList }
                    })
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "cell(has_fleet_access)",
            fn: function(row) {
              return [
                row.item.roles && _vm.hasRole(row.item.roles, "fleet_user")
                  ? _c("span", [_vm._v("Yes")])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "green-divider" }),
      _c(
        "div",
        {
          staticClass:
            "flex-row w-100 justify-content-between align-items-center"
        },
        [
          _c("div", [_vm._v(" Results: " + _vm._s(_vm.totalRows) + " ")]),
          _c(
            "div",
            { staticClass: "flex-row align-items-center" },
            [
              _c("b-form-select", {
                staticClass: "page-select",
                attrs: {
                  variant: "dark",
                  id: "perPageSelect",
                  size: "sm",
                  options: _vm.pageOptions
                },
                model: {
                  value: _vm.perPage,
                  callback: function($$v) {
                    _vm.perPage = $$v
                  },
                  expression: "perPage"
                }
              }),
              _c("b-pagination", {
                staticClass: "page-buttons",
                attrs: {
                  variant: "dark",
                  "total-rows": _vm.totalRows,
                  "per-page": _vm.perPage,
                  align: "fill",
                  size: "sm"
                },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-edit-user",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Edit User",
            "no-close-on-backdrop": ""
          }
        },
        [
          _c("admin-edit-user", {
            attrs: {
              "is-modal": "",
              modal: "modal-edit-user",
              user: _vm.clickedUser
            },
            on: {
              save: function($event) {
                return _vm.onSave()
              }
            }
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "modal-assign-device",
            centered: "",
            size: "xl",
            "hide-footer": "",
            title: "Assign Device to User"
          }
        },
        [
          _c("admin-assign-devices", {
            attrs: { user: _vm.clickedUser, modal: "modal-assign-device" },
            on: {
              save: function($event) {
                return _vm.onAssign()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }